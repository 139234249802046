import { destroyCookie, parseCookies, setCookie } from 'nookies';

import { DataStorage } from '../../domain/usecases/data-storage';

export class CookiesAdapter implements DataStorage {
  getItem(key: string) {
    const cookies = parseCookies();
    return cookies[key];
  }
  setItem(key: string, value: string) {
    setCookie(null, key, value, {
      domain: '.sade.app',
    });
  }
  removeItem(key: string) {
    destroyCookie(null, key, {
      domain: '.sade.app',
    });
  }
}
