import { Institution, InstitutionUnit, Professional } from 'domain/models/data';
import { createContext } from 'react';

type AppContextType = {
  institution: Institution;
  institutionUnit: InstitutionUnit;
  currentProfessional: Professional;
};

export const AppContext = createContext<AppContextType>({
  institution: {},
  institutionUnit: {},
  currentProfessional: {},
});
