import { gql } from '@apollo/client';
export const LIST_EXAM_IMAGE_QUERY = gql`
  query ExamImage($limit: Int, $offset: Int, $ordering: [String], $id: UUID, $exam_Id: UUID, $sopInstanceUid: String, $uploadStatus: String) {
    listExamImage(limit: $limit, offset: $offset, ordering: $ordering, id: $id, exam_Id: $exam_Id, sopInstanceUid: $sopInstanceUid, uploadStatus: $uploadStatus) {
      results {
        id
        createdAt
        modifiedAt
        selected
        sopInstanceUid
        fileDicomUrl
        fileImageUrl
        s3Bucket
        signedImageUrl
        signedDicomUrl
        uploadStatus
      }
      totalCount
    }
  }
`;
