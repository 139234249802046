import { camelCase, set, transform } from 'lodash';
import { get } from 'lodash';
import { isArray, isObjectLike, isPlainObject, map } from 'lodash/fp';

export function capitalizeFirstLetter(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

type ObjectLiteral = {
  [key: string]: any;
};
export function removeEmptyKeysFromObject(object: ObjectLiteral) {
  for (const propName in object) {
    // Don't remove false and 0
    if (object[propName] === null || object[propName] === undefined || object[propName] === '') {
      delete object[propName];
      continue;
    }
    if (isArray(object[propName])) {
      object[propName] = removeEmptyKeysFromObject(object[propName]);
    }
  }
  return object;
}

export function removeKeysFromObject(object: ObjectLiteral, keys: string[]) {
  for (const key of keys) {
    if (get(object, key) !== undefined) delete object[key];
  }
  return object;
}

export function getDomainWithProtocol() {
  const [protocol, , domain] = window.location.href.split('/');
  return `${protocol}//${domain}`;
}

export function slugify(str: string) {
  return str
    .normalize('NFKD')
    .toLowerCase()
    .replace(/[^\w\s-]/g, '')
    .trim()
    .replace(/[-\s]+/g, '_');
}

export function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function capitalizeString(value: string) {
  const words = value.toLowerCase().split(' ');
  return words.map((w) => capitalizeFirstLetter(w)).join(' ');
}

export function getStatusColor(status: string): string {
  const colorMap = new Map();
  colorMap.set('SCHEDULED', 'warning');
  colorMap.set('CANCELED', 'danger');
  colorMap.set('AWAITING', 'dark');
  colorMap.set('REALIZED', 'primary');
  colorMap.set('ATTENDING', 'info');
  colorMap.set('DONE', 'success');
  return colorMap.get(status) || 'primary';
}

function createIteratee(converter: any, self: any) {
  return (result: any, value: any, key: any) => set(result, converter(key), isObjectLike(value) ? self(value) : value);
}

export function snakeCaseToCamelCase(node: any): any {
  if (isArray(node)) return map(snakeCaseToCamelCase, node);
  if (isPlainObject(node)) return transform(node, createIteratee(camelCase, snakeCaseToCamelCase));
  return node;
}

export const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export function uniques<T>(list: ObjectLiteral[], key: string): T[] {
  const seen = new Set();
  return list.filter((item) => {
    const duplicate = seen.has(item[key]);
    seen.add(item[key]);
    return !duplicate;
  }) as T[];
}

export const NumberReal = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export const findLastInArray = <T>(array: T[], predicate: (value: T, index: number, obj: T[]) => boolean) => {
  for (let i = array.length - 1; i >= 0; i--) {
    if (predicate(array[i], i, array)) {
      return array[i];
    }
  }
  return undefined;
};

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const strToNumber = (value: string) => {
  if (value === '') return 0;
  return parseFloat(String(value).trim().replace(',', '.'));
};
