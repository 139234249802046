import { gql } from '@apollo/client';

const authString = `
query CheckAuth {
    checkAuth {
        status
    }
}
`;

export const CHECK_AUTH = gql`
  ${authString}
`;
