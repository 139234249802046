import { gql } from '@apollo/client';

export const EXAM_TO_LAUDO_QUERY = gql`
  query ExamToLaudo($id: UUID) {
    listExam(id: $id) {
      results {
        id
        modifiedAt
        requestingPhysician
        performingPhysicians {
          id
          name
        }
        note
        studyInstanceUid
        examDatetime
        accessionNumber
        status
        priority
        realizedDate
        pdfUploaded
        examType {
          id
          createdAt
          modifiedAt
          description
          code
        }
        procedure {
          id
          createdAt
          modifiedAt
          description
        }
        patient {
          id
          createdAt
          modifiedAt
          name
          sex
          email
          photo
          birthDate
          father
          mother
          telephones
        }
        healthPlan {
          id
          name
        }
        createdBy {
          id
          createdAt
          modifiedAt
          name
          sex
          email
          photo
          birthDate
          father
          mother
          telephones
        }
        updatedBy {
          id
          createdAt
          modifiedAt
          name
          sex
          email
          photo
          birthDate
          father
          mother
          telephones
        }
        equipment {
          id
          createdAt
          modifiedAt
          name
          aeTitle
        }
        images {
          id
          createdAt
          modifiedAt
          selected
          sopInstanceUid
          signedImageUrl
          s3Bucket
          uploadStatus
        }
        result {
          id
          createdAt
          modifiedAt
          recommendation
          technique
          analyze
          diagnosticPrinting
          finished
        }
      }
      totalCount
    }
  }
`;

export const EXAM_LIST_QUERY = gql`
  query ListExam(
    $limit: Int
    $offset: Int
    $ordering: [String]
    $id: UUID
    $patient_Name_Icontains: String
    $studyInstanceUid: String
    $examDatetime_Range: [DateTime]
    $accessionNumber: Int
    $status: String
    $performingPhysicians_Id: UUID
    $examType_Id: UUID
  ) {
    listExam(
      limit: $limit
      offset: $offset
      ordering: $ordering
      id: $id
      patient_Name_Icontains: $patient_Name_Icontains
      studyInstanceUid: $studyInstanceUid
      examDatetime_Range: $examDatetime_Range
      accessionNumber: $accessionNumber
      status: $status
      performingPhysicians_Id: $performingPhysicians_Id
      examType_Id: $examType_Id
    ) {
      results {
        id
        modifiedAt
        requestingPhysician
        performingPhysicians {
          id
          name
          documents {
            documentType
            documentValue
          }
        }
        pdfUploaded
        note
        studyInstanceUid
        examDatetime
        accessionNumber
        status
        priority
        realizedDate
        accessKey
        accessCode
        examType {
          id
          createdAt
          modifiedAt
          description
          code
        }
        procedure {
          id
          createdAt
          modifiedAt
          description
        }
        patient {
          id
          createdAt
          modifiedAt
          name
          sex
          email
          photo
          birthDate
          father
          mother
          telephones
          documents {
            documentType
            documentValue
          }
        }
        healthPlan {
          id
          name
        }
        createdBy {
          id
          createdAt
          modifiedAt
          name
          sex
          email
          photo
          birthDate
          father
          mother
          telephones
        }
        updatedBy {
          id
          createdAt
          modifiedAt
          name
          sex
          email
          photo
          birthDate
          father
          mother
          telephones
        }
        equipment {
          id
          createdAt
          modifiedAt
          name
          aeTitle
        }
        result {
          id
          createdAt
          modifiedAt
          finished
          resultExtraFields
          medicReport
        }
        images {
          id
          selected
        }
      }
      totalCount
    }
  }
`;

export const EXAM_DATA_FORM = gql`
  query {
    listExamTypes(enabled: true) {
      results {
        id
        description
        code
        enabled
      }
    }
    listEquipment {
      results {
        name
        aeTitle
        id
      }
    }
    listHealthPlan {
      results {
        id
        name
      }
    }
  }
`;

export const LIST_EXAM_LOGS = gql`
  query ListExamLogs($exam_Id: UUID, $ordering: [String]) {
    listExamLogs(exam_Id: $exam_Id, ordering: $ordering) {
      results {
        id
        createdAt
        modifiedAt
        before
        after
        user {
          name
        }
      }
    }
  }
`;

export const PROCEDURE_LIST_QUERY = gql`
  query ListExamProcedures($limit: Int, $offset: Int, $ordering: [String], $id: UUID, $description_Icontains: String, $examType_Id: UUID) {
    listExamProcedures(limit: $limit, offset: $offset, ordering: $ordering, id: $id, description_Icontains: $description_Icontains, examType_Id: $examType_Id) {
      results {
        id
        description
        examType {
          description
          code
          enabled
          id
        }
        additionalFields {
          id
          name
          fieldType
          fieldValue
          fieldOptions
        }
      }
      totalCount
    }
  }
`;

export const EXAM_TYPE_LIST_QUERY = gql`
  query ExamTypeList($limit: Int, $offset: Int, $ordering: [String], $id: UUID, $description_Icontains: String, $code: String, $enabled: Boolean, $institutions_Id: UUID) {
    listExamTypes(limit: $limit, offset: $offset, ordering: $ordering, id: $id, description_Icontains: $description_Icontains, code: $code, enabled: $enabled, institutions_Id: $institutions_Id) {
      results {
        id
        code
        description
      }
      totalCount
    }
  }
`;
