import { gql } from '@apollo/client';

export const USER_GROUP_LIST = gql`
  query ListGroupUser {
    listUserGroup {
      id
      name
    }
  }
`;

export const USER_SIGNATURE = gql`
  query UserSignature {
    userSignature {
      fileEncoded
    }
  }
`;
