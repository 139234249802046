import { gql } from '@apollo/client';
export const DICOM_SERVER_LIST_QUERY = gql`
  query DicomServerDatatables($limit: Int, $offset: Int, $ordering: [String], $id: UUID, $aeTitle_Icontains: String) {
    listDicomServer(limit: $limit, offset: $offset, ordering: $ordering, id: $id, aeTitle_Icontains: $aeTitle_Icontains) {
      results {
        id
        host
        port
        aeTitle
        s3RootPath
        equipments {
          id
          name
          aeTitle
        }
      }
      totalCount
    }
  }
`;
