import { Menu } from 'domain/models/data';
import { AppContext } from 'presentation/context/AppContext';
import { CalendarBoldDuoIcon, HealthMedicineIcon, SettingsDuoIcon, UserGroupIcon } from 'presentation/icons';
import { UserHeartIcon } from 'presentation/icons/user-heart';
import { useContext, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import { IRootState } from '../../store';
import { toggleSidebar } from '../../store/themeConfigSlice';
import { getMenuIcon } from './menu-icon';

const Sidebar = () => {
  const [currentMenu, setCurrentMenu] = useState<string>('');
  const [errorSubMenu, setErrorSubMenu] = useState(false);
  const { currentProfessional } = useContext(AppContext);
  const [userMenus, setUserMenus] = useState<Menu[]>([]);
  const themeConfig = useSelector((state: IRootState) => state.themeConfig);
  const semidark = useSelector((state: IRootState) => state.themeConfig.semidark);
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toggleMenu = (value: string) => {
    setCurrentMenu((oldValue) => {
      return oldValue === value ? '' : value;
    });
  };

  useEffect(() => {
    const selector = document.querySelector('.sidebar ul a[href="' + window.location.pathname + '"]');
    if (selector) {
      selector.classList.add('active');
      const ul: any = selector.closest('ul.sub-menu');
      if (ul) {
        let ele: any = ul.closest('li.menu').querySelectorAll('.nav-link') || [];
        if (ele.length) {
          ele = ele[0];
          setTimeout(() => {
            ele.click();
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth < 1024 && themeConfig.sidebar) {
      dispatch(toggleSidebar());
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    const mainMenu = currentProfessional?.user?.menus.filter((m) => !m.parent) || ([] as Menu[]);
    setUserMenus(mainMenu);
  }, [currentProfessional]);

  const MenuItem = (menu: Menu) => {
    return (
      <li key={menu.id} className="menu nav-item">
        <NavLink className="nav-link" to={`${menu.path}`}>
          <div className="flex items-center">
            {getMenuIcon(menu.name as string)}
            <span className="px-1">{menu.name}</span>
          </div>
        </NavLink>
      </li>
    );
  };

  return (
    <div className={semidark ? 'dark' : ''}>
      <nav className={`sidebar fixed min-h-screen h-full top-0 bottom-0 w-[260px] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] z-50 transition-all duration-300 ${semidark ? 'text-white-dark' : ''}`}>
        <div className="bg-white dark:bg-black h-full">
          <div className="flex justify-between items-center px-4 py-3">
            <NavLink to="/" className="main-logo flex items-center shrink-0">
              <img className="ml-[5px] flex-none" height={138} src="/assets/images/sade.svg" alt="logo" />
            </NavLink>

            <button
              type="button"
              className="collapse-icon w-8 h-8 rounded-full flex items-center hover:bg-gray-500/10 dark:hover:bg-dark-light/10 dark:text-white-light transition duration-300 rtl:rotate-180"
              onClick={() => dispatch(toggleSidebar())}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 m-auto">
                <path d="M13 19L7 12L13 5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path opacity="0.5" d="M16.9998 19L10.9998 12L16.9998 5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </div>
          <PerfectScrollbar className="h-[calc(100vh-80px)] relative">
            <ul className="relative font-semibold space-y-0.5 p-4 py-0">
              {userMenus.map((menu) => {
                if (menu.submenus?.length) {
                  return (
                    <li key={menu.id} className="menu nav-item">
                      <button type="button" className={`${currentMenu === menu.id ? 'active' : ''} nav-link group w-full`} onClick={() => toggleMenu(menu.id)}>
                        <div className="flex items-center">
                          {getMenuIcon(menu.name as string)}
                          <span className="ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">{menu.name}</span>
                        </div>
                        <div className={currentMenu === menu.id ? '!rotate-90' : 'rtl:rotate-180'}>
                          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 5L15 12L9 19" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </div>
                      </button>
                      <AnimateHeight duration={300} height={currentMenu === menu.id ? 'auto' : 0}>
                        <ul className="sub-menu text-gray-500">
                          {menu.submenus.map((sm) => {
                            return (
                              <li key={sm.id}>
                                <NavLink to={`${sm.path}`}>{sm.name}</NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      </AnimateHeight>
                    </li>
                  );
                }
                return <MenuItem key={menu.id} {...menu} />;
              })}
            </ul>
          </PerfectScrollbar>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
