import { gql } from '@apollo/client';

export const ATTENDANCE_PROCEDURE_RESULT_MUTATION = gql`
  mutation AttendanceProcedureResult($data: AttendanceProcedureResultSerializerMutationInput!) {
    attendanceProcedureResult(input: $data) {
      id
    }
  }
`;

export const SIGN_ATTENDANCE_PROCEDURE_RESULT_MUTATION = gql`
  mutation ResultUploadSigned($data: PdfSignInput) {
    resultUploadSigned(input: $data) {
      response
    }
  }
`;

export const ATTENDANCE_PROCEDURE_RESULT_PDF_TOKEN_MUTATION = gql`
  mutation ResultSecurityToken($data: PdfSecurityTokenInput) {
    resultSecurityToken(input: $data) {
      url
    }
  }
`;
