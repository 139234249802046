import { gql } from '@apollo/client';

export const PATIENT_MUTATION = gql`
  mutation Patient($data: PatientMutationInput!) {
    patient(input: $data) {
      id
      name
      sex
      email
      photo
      birthDate
      father
      mother
      telephones
    }
  }
`;

export const PROFESSIONAL_MUTATION = gql`
  mutation Professional($data: ProfessionalMutationInput!) {
    professional(input: $data) {
      id
      name
      sex
      email
      photo
      birthDate
      father
      mother
      telephones
    }
  }
`;

export const PATIENT_ASSOCIATION_MUTATION = gql`
  mutation PatientAssociate($data: PatientAssociationInput) {
    patientAssociate(input: $data) {
      response
    }
  }
`;
