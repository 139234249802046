import { gql } from '@apollo/client';
import { GraphqlQueryParams, ResultKeys } from 'domain/models/graphql';
import { capitalizeFirstLetter } from 'utils';

interface QueryIncludeStrategy {
  getQueryValue: (key: string, value: any) => any;
}

class ObjectQueryStrategy implements QueryIncludeStrategy {
  getQueryValue(key: string, value: any) {
    return `
        ${key} {
          ${getIncludeAsString(value)}
        }`;
  }
}

class FunctionQueryStrategy implements QueryIncludeStrategy {
  getQueryValue(key: string, value: Function) {
    return `
        ${key}():
        `;
  }
}

class DefaultQueryStrategy implements QueryIncludeStrategy {
  getQueryValue(key: string, value: any) {
    return value
      ? `
    ${key}
    `
      : '';
  }
}

const getIncludeAsString = (include: any) => {
  let response = '';
  const strategies = new Map<string, QueryIncludeStrategy>([
    ['object', new ObjectQueryStrategy()],
    ['function', new FunctionQueryStrategy()],
  ]);
  const defaultStrategy = new DefaultQueryStrategy();
  Object.keys(include).forEach((key) => {
    const value = include[key];
    const strategyKey = typeof value;
    const strategy = strategies.get(strategyKey) || defaultStrategy;
    response += strategy.getQueryValue(key, value);
  });
  return response;
};

const createQueryParams = (filterKeys: any, keyPrefix: string = '') => {
  return Object.keys(filterKeys).reduce((p, key) => {
    //@ts-ignore
    const typeValue = filterKeys[key];
    return (
      p +
      `
    $${keyPrefix}${key}: ${typeValue}
    `
    );
  }, '');
};

const createFilterKeysQuery = (filterKeys: any, keyPrefix: string = '') => {
  return Object.keys(filterKeys).reduce((p, key) => {
    return (
      p +
      `
  ${key}: $${keyPrefix}${key}
`
    );
  }, '');
};

export const makeGraphqlPaginateQuery = <T, Q>(params: GraphqlQueryParams<T, Q>) => {
  const paginationKeys = {
    limit: 'Int',
    offset: 'Int',
    ordering: '[String]',
    before: 'String',
    after: 'String',
    first: 'Int',
    last: 'Int',
  };
  const filterKeys = { ...params.filterKeys, ...paginationKeys };
  const keyPrefix = params.useQueryNamePrefix ? `${params.queryName}_` : '';
  const filterKeysParams = createQueryParams(filterKeys, keyPrefix);
  const filterKeyQuery = createFilterKeysQuery(filterKeys, keyPrefix);
  const { include } = params;
  const includes = Array.isArray(include) ? include.join(`\n`) : getIncludeAsString(params.include);

  const gqlQuery = `
query ${params.queryName.toUpperCase()}(
  ${filterKeysParams}
  ){
    ${params.queryName}(
      ${filterKeyQuery}
    ){
      results {
        ${includes}
      }
      totalCount
    }
  }
`;
  return gql`
    ${gqlQuery}
  `;
};

type MutationFactoryParams<T> = {
  serializerName?: string;
  queryName: string;
  responseFields?: ResultKeys<T>;
};
export const makeMutationQuery = <T>(params: MutationFactoryParams<T>) => {
  const { serializerName, queryName, responseFields } = params;
  const responseFieldsStr = responseFields ? getIncludeAsString(responseFields) : 'id';
  const serializerNameStr = serializerName || `${capitalizeFirstLetter(queryName)}SerializerMutationInput`;
  const mutationQuery = `
    mutation ${queryName.toUpperCase()}($data: ${serializerNameStr}!) {
        ${queryName}(input: $data) {
            ${responseFieldsStr}
        }
    }
    `;
  return gql`
    ${mutationQuery}
  `;
};
