function getProxyUrl() {
  const protocol = window.location.protocol;
  let proxy = import.meta.env.VITE_APP_PROXY_URL || 'http://localhost:7000';
  const protocols = ['http:', 'https:'];
  protocols.forEach((p) => {
    proxy = proxy.replace(p, `${protocol}`);
  });
  return proxy;
}

function getTenantHost() {
  return import.meta.env.VITE_APP_TENANT_HOST || window.location.host;
}

export default {
  PROXY_URL: getProxyUrl(),
  DEVELOPMENT: import.meta.env.MODE == 'development',
  TIMBRADO: import.meta.env.VITE_APP_TIMBRADO as string,
  DEV_USER: import.meta.env.VITE_APP_DEV_USER || '',
  DEV_PASSWORD: import.meta.env.VITE_APP_DEV_PASSWORD || '',
  API_URL: import.meta.env.VITE_APP_API_URL || '',
  TENANT_HOST: getTenantHost(),
};
