import { gql } from '@apollo/client';

export const HEALTH_PLAN_MUTATION = gql`
  mutation UpdateHealthPlan($data: HealthPlanSerializerMutationInput!) {
    healthPlan(input: $data) {
      id
      name
    }
  }
`;
