import { gql } from '@apollo/client';
import { GqlQueryData as SadeGQLQueryData } from '@diegodfreire/sade-gql-client-js/dist/data/usecase/gql-data';
import { GQLMutationData, GqlQueryData } from 'data/usecase/gql-data';

import { executeMutation, executeQuery } from './client';

export class GQLQueryRequest {
  private queryDatas: GqlQueryData[] = [];

  add(item: GqlQueryData | SadeGQLQueryData) {
    this.queryDatas.push(item as GqlQueryData);
    return this;
  }

  execute<R = any>(): Promise<R> {
    this.queryDatas.forEach((item) => item.buildDocumentToManyQuery());
    const paramsNames = this.queryDatas.map((item) => item.getQueryParamsNames()).join(',');
    const queries = this.queryDatas.map((item) => item.getQuery()).join('\n');
    const variables = this.queryDatas.reduce((prev, item) => {
      return { ...prev, ...item.getVariables() };
    }, {});
    const gqlQueryString = `query LIST_MANY_ITEMS(${paramsNames}){
            ${queries}
        }`;

    const query = gql`
      ${gqlQueryString}
    `;

    return executeQuery(query, variables);
  }
}

export class GQLMutationRequest {
  private mutations: GQLMutationData[] = [];

  add(item: GQLMutationData) {
    this.mutations.push(item);
    return this;
  }

  execute<R = any>(): Promise<R> {
    const paramsNames = this.mutations.map((item, index) => `$${item.getName()}_${index}` + `: ${item.getSerializer()}`).join(',');
    const queries = this.mutations.map((item, index) => item.getQuery(index)).join('\n');
    const variables = this.mutations.reduce((prev, item, index) => {
      return { ...prev, [`${item.getName()}_${index}`]: item.getVariables() };
    }, {});
    const gqlQueryString = `mutation (${paramsNames}){
            ${queries}
    }`;

    const query = gql`
      ${gqlQueryString}
    `;

    return executeMutation<any>(query, variables);
  }
}
