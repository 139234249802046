import { gql } from '@apollo/client';

export const AUTH_MUTATION = gql`
  mutation Login($username: String!, $password: String!) {
    login(data: { username: $username, password: $password }) {
      token
      user {
        name
        photo
      }
    }
  }
`;
