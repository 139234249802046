import env from 'config/env';
import { GqlListUserGroup, GqlUserResetPassword, LocalUserData, RestListMenuUser } from 'data/usecase';
import { LocalStorageAdapter } from 'main/adapter';
import { CookiesAdapter } from 'main/adapter/cookies';
import { makeHttpRequest } from 'utils';

export const makeUserData = () => {
  const dataStorage = env.DEVELOPMENT ? new LocalStorageAdapter() : new CookiesAdapter();
  return new LocalUserData(dataStorage);
};

export const makeListUserGroup = () => {
  return new GqlListUserGroup();
};

export const makeUserResetPassword = () => {
  return new GqlUserResetPassword();
};

export const makeListMenuUser = () => new RestListMenuUser(makeHttpRequest());
