import { CalendarBoldDuoIcon, HealthMedicineIcon, SettingsDuoIcon, StatisticDuoBoldIcon, UserGroupIcon, UserHeartIcon } from 'presentation/icons';

export const getMenuIcon = (name: string) => {
  const iconMap = new Map([
    ['Atendimento', <CalendarBoldDuoIcon key="attendance" />],
    ['Pacientes', <UserHeartIcon key="pacientes" />],
    ['Profissionais', <UserGroupIcon key="Profissionais" />],
    ['Convenios', <HealthMedicineIcon key="Convenios" />],
    ['Configurações', <SettingsDuoIcon key="Configurações" />],
    ['Analítico', <StatisticDuoBoldIcon key="Analítico" />],
  ]);
  return iconMap.get(name);
};
