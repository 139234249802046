import { twMerge } from 'tailwind-merge';

import { IconProps } from './icon.types';

export const ArrowDownDuoIcon = ({ className = '', color = 'currentColor', size = 20 }: IconProps) => {
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 9L12 15L5 9" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const ArrowDownIcon = ({ className = '', color = 'currentColor', size = 20 }: IconProps) => {
  return (
    <svg className={twMerge('rotate-90', className)} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 5L15 12L9 19" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
