import { Institution } from 'domain/models/data';
import { GQLListResponse, InstitutionQuery } from 'domain/models/graphql';
import { GetInstitutionById, InstitutionByIdParams, ListInstitution, ListInstitutionParams, ListInstitutionResponse } from 'domain/usecases';
import { executeQuery } from 'infra/graphql/client';
import { makeInstitutionListQuery } from 'infra/graphql/queries';

export class GqlLisInstitution implements ListInstitution {
  async fetch(params: ListInstitutionParams): Promise<GQLListResponse<Institution>> {
    const { filter, include } = params;
    if (!filter.limit) {
      filter.limit = 10;
    }
    const query = makeInstitutionListQuery({ include });
    const { listInstitution } = await executeQuery<ListInstitutionResponse, InstitutionQuery>(query, filter, 'cache-first');
    return listInstitution;
  }
}

export class GqlGetInstitutionById implements GetInstitutionById {
  constructor(private readonly listInstitution: ListInstitution) {}
  async fetch({ id, include }: InstitutionByIdParams) {
    const { results } = await this.listInstitution.fetch({
      filter: { id },
      include,
    });
    const [item] = results;
    return item;
  }
}
