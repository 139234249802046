import { gql } from '@apollo/client';

export const PATIENT_EXAM_RESULT_QUERY = gql`
  query PatientExamResult($accessKey: Int!, $accessCode: String!) {
    patientExamResult(accessKey: $accessKey, accessCode: $accessCode) {
      patient {
        id
        createdAt
        modifiedAt
        name
        sex
        email
        photo
        birthDate
        father
        mother
        telephones
      }
      pdfUploaded
      images {
        id
        selected
      }
      status
      realizedDate
      requestingPhysician
      performingPhysicians {
        id
        name
      }
      procedure {
        description
      }
      healthPlan {
        id
        name
      }
      result {
        id
        finished
        resultExtraFields
      }
      accessToken
    }
  }
`;
