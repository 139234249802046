import { DocumentNode, print } from 'graphql';

export abstract class GqlQueryData<F = any, K = any> {
  protected filters: F = {} as F;
  protected includes: K = {} as K;
  protected documentNode: DocumentNode | undefined = undefined;
  private gqlQuery: string = '';
  protected useMultipleQuery = false;

  filter(filters: F) {
    this.filters = { ...this.filters, ...filters };
    return this;
  }

  setMultipleQuery(flag: boolean) {
    this.useMultipleQuery = flag;
    return this;
  }

  getVariables() {
    const filters: { [key: string]: any } = this.filters as any;
    return Object.keys(filters).reduce((prev, key) => {
      return {
        ...prev,
        [this.getQueryName() + '_' + key]: filters[key],
      };
    }, {});
  }

  include(include: K) {
    this.includes = { ...this.includes, ...include };
    return this;
  }

  protected abstract buildDocumentNode(): DocumentNode;
  protected abstract getQueryName(): string;

  private buildGqlQuery() {
    if (!this.documentNode) {
      this.buildDocumentNode();
    }
    if (this.documentNode) {
      this.gqlQuery = print(this.documentNode);
    }
  }

  buildDocumentToManyQuery() {
    this.setMultipleQuery(true);
    this.buildDocumentNode();
  }

  getQueryParamsNames() {
    if (!this.gqlQuery) {
      this.buildGqlQuery();
    }
    const matches = this.gqlQuery.match(/\((.*?)\)/);
    if (matches?.length && matches.length > 1) {
      return matches[1];
    }
  }

  getQuery() {
    if (!this.gqlQuery) {
      this.buildGqlQuery();
    }
    const matches = this.gqlQuery.match(/\{([^]+)\}/);
    if (matches?.length && matches.length > 1) {
      return matches[1];
    }
  }
}

export abstract class GQLMutationData<F = any, K = any> {
  protected input: F = {} as F;
  abstract getSerializer(): string;
  abstract getName(): string;
  abstract getVariables(): F;
  getQuery(index: number) {
    return `
        ${this.getName()}_${index}: ${this.getName()}(input: $${this.getName()}_${index}) {
                id
        }
    `;
  }

  addInput(input: Partial<F>) {
    this.input = { ...this.input, ...input };
    return this;
  }
}
