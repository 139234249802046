import { useEffect, useState } from 'react';

const isClientRender = typeof window !== 'undefined';

// Copied from https://usehooks.com/useLocalStorage
// added listener to "storage" after that
const useLocalStorage = (key: string, initialValue: any) => {
  const item = isClientRender && window?.localStorage?.getItem(key);
  const [storedValue, setStoredValue] = useState(item || initialValue);

  const updateItem = () => {
    setStoredValue(window.localStorage?.getItem(key));
  };

  useEffect(() => {
    if (item) {
      setStoredValue(item);
    }

    window.addEventListener('storage', updateItem);
    return () => window.removeEventListener('storage', updateItem);
  }, [item, updateItem]);

  const setValue = (value: any) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);
      if (isClientRender) {
        const storage = window?.localStorage;
        if (valueToStore != null) {
          storage?.setItem(key, valueToStore);
        } else {
          storage?.removeItem(key);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue];
};

export default useLocalStorage;
