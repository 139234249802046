type ObjectLiteral = {
  [key: string]: any;
};

const processArray = (items: any[], recursiveFunc: Function): any[] => {
  return items.map((item) => {
    if (typeof item === 'object') {
      return recursiveFunc(item);
    } else if (Array.isArray(item)) {
      return processArray(item, recursiveFunc);
    }
    return item;
  });
};

export const removeTypename = (obj: ObjectLiteral): ObjectLiteral => {
  return Object.keys(obj).reduce((curr, key) => {
    if (key === '__typename') {
      return curr;
    }

    const value = obj[key];
    if (Array.isArray(value)) {
      const arrayValues = processArray(value, removeTypename);
      return { ...curr, [key]: arrayValues };
    } else if (value && typeof value === 'object') {
      return { ...curr, [key]: removeTypename(value) };
    }
    return { ...curr, [key]: value };
  }, {});
};

export const convertEmptyStringToNull = (obj: ObjectLiteral): ObjectLiteral => {
  return Object.keys(obj).reduce((curr, key) => {
    const value = obj[key];
    if (value === '') {
      return { ...curr, [key]: null };
    }

    if (Array.isArray(value)) {
      const arrayValues = processArray(value, convertEmptyStringToNull);
      return { ...curr, [key]: arrayValues };
    } else if (value && typeof value === 'object') {
      return { ...curr, [key]: convertEmptyStringToNull(value) };
    }
    return { ...curr, [key]: value };
  }, {});
};
