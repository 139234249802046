import { gql } from '@apollo/client';

export const EXAM_PROCEDURE_MUTATION = gql`
  mutation ExamProcedure($data: ExamProcedureSerializerMutationInput!) {
    examProcedure(input: $data) {
      id
    }
  }
`;

export const EXAM_SAVE_MUTATION = gql`
  mutation CreateExam($data: ExamSerializerMutationInput!) {
    exam(input: $data) {
      id
    }
  }
`;
