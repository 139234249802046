import { gql } from '@apollo/client';
export const CONTENT_MODEL_LIST_QUERY = gql`
  query ContentModelDatatables(
    $limit: Int
    $offset: Int
    $ordering: [String]
    $before: String
    $after: String
    $first: Int
    $last: Int
    $id: UUID
    $owner_Id: UUID
    $modelType: String
    $name_Icontains: String
    $publicAndPrivateOnly: Boolean
    $procedures_Id: UUID
    $procedures_Id_In: [UUID]
  ) {
    listContentModel(
      limit: $limit
      offset: $offset
      ordering: $ordering
      before: $before
      after: $after
      first: $first
      last: $last
      id: $id
      owner_Id: $owner_Id
      modelType: $modelType
      name_Icontains: $name_Icontains
      publicAndPrivateOnly: $publicAndPrivateOnly
      procedures_Id: $procedures_Id
      procedures_Id_In: $procedures_Id_In
    ) {
      results {
        id
        createdAt
        modifiedAt
        name
        htmlContent
        isPrivate
        modelType
        owner {
          id
          name
        }
        procedures {
          id
          description
        }
      }
      totalCount
    }
  }
`;
