import { gql } from '@apollo/client';

export const CONTENT_MODEL_MUTATION = gql`
  mutation ContentModelMutation($data: ContentModelSerializerMutationInput!) {
    contentModel(input: $data) {
      id
      name
      htmlContent
      isPrivate
      modelType
      owner
    }
  }
`;
