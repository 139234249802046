const Portals = () => {
  return (
    <div>
      <div id="popper-portal"></div>
      <div id="modal-portal"></div>
    </div>
  );
};

export default Portals;
