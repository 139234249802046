import { Institution } from 'domain/models/data';
import { GraphqlQueryParams, InstitutionQuery } from 'domain/models/graphql';

import { makeGraphqlPaginateQuery } from './gql-query-factory';

export type InstitutionGqlParams = Omit<GraphqlQueryParams<Institution, InstitutionQuery>, 'queryName' | 'filterKeys'>;

export const makeInstitutionListQuery = (params: InstitutionGqlParams) => {
  return makeGraphqlPaginateQuery<Institution, InstitutionQuery>({
    queryName: 'listInstitution',
    filterKeys: {
      id: 'UUID',
    },
    ...params,
  });
};
