import { gql } from '@apollo/client';

export const EXAM_IMAGE_MUTATION = gql`
  mutation ExamImageMutation($data: ExamImageSerializerMutationInput!) {
    examImage(input: $data) {
      sopInstanceUid
      id
      uploadStatus
      fileDicomUrl
      fileImageUrl
      s3Bucket
      errorMessage
      selected
    }
  }
`;
