import { gql } from '@apollo/client';

export const USER_RESET_PASSWORD_MUTATION = gql`
  mutation UpdatePassword($data: ResetPasswordInput) {
    changePassword(input: $data) {
      success
      message
    }
  }
`;
