import env from 'config/env';
import { Menu } from 'domain/models/data';
import { ResetPasswordMutation } from 'domain/models/graphql';
import { ListUserMenu, ResetPasswordInput, UserAuhenticated, UserGroupResponse, UserResetPassword } from 'domain/usecases';
import { ListUserGroup, UserData } from 'domain/usecases';
import { DataStorage } from 'domain/usecases/data-storage';
import { DocumentNode } from 'graphql';
import { executeMutation, executeQuery } from 'infra/graphql/client';
import { USER_RESET_PASSWORD_MUTATION } from 'infra/graphql/mutations';
import { USER_GROUP_LIST } from 'infra/graphql/queries';

import { GqlQueryData } from './gql-data';

export class LocalUserData implements UserData {
  constructor(private readonly dataStorage: DataStorage) {}
  setUserData(data: UserAuhenticated) {
    this.setToken(data.access);
    this.setPersonName(data.person_name);
    this.setInstitution(data.institution);
    this.setPersonId(data.person_id);
    this.dataStorage.setItem('person.can_performer_attendance_procedure', String(data.can_performer_attendance_procedure));
    this.dataStorage.setItem('person.can_make_result', String(data.can_make_result));
  }

  getToken() {
    return this.dataStorage.getItem('token');
  }
  setToken(token: string) {
    return this.dataStorage.setItem('token', token);
  }
  getInstitution() {
    return this.dataStorage.getItem('institution') as string;
  }
  setInstitution(institutionId: string) {
    return this.dataStorage.setItem('institution', institutionId);
  }
  getPersonName() {
    const storageValue = this.dataStorage.getItem('person')?.replaceAll('+', ' ') || '';
    return decodeURI(storageValue);
  }
  setPersonId(valeu: string) {
    this.dataStorage.setItem('person.id', valeu);
  }
  getPersonId() {
    return this.dataStorage.getItem('person.id') || undefined;
  }
  setPersonName(personName: string) {
    return this.dataStorage.setItem('person', encodeURI(personName));
  }
  getActionPermission(): string[] {
    return this.dataStorage.getItem('person.action_permissions')?.split(',') || [];
  }
  canPerformerAttendanceProcedure() {
    return this.dataStorage.getItem('person.can_performer_attendance_procedure') === 'True' || this.dataStorage.getItem('person.can_performer_attendance_procedure') === 'true';
  }
  getCanMakeResult() {
    return this.dataStorage.getItem('person.can_make_result') === 'True';
  }
  clearData() {
    this.dataStorage.removeItem('person');
    this.dataStorage.removeItem('person.id');
    this.dataStorage.removeItem('institution');
    this.dataStorage.removeItem('token');
    this.dataStorage.removeItem('refresh_token');
    this.dataStorage.removeItem('person.can_make_result');
    this.dataStorage.removeItem('person.can_performer_attendance_procedure');
  }
}

export class GqlListUserGroup extends GqlQueryData implements ListUserGroup {
  protected buildDocumentNode(): DocumentNode {
    return USER_GROUP_LIST;
  }
  protected getQueryName(): string {
    return 'listUserGroup';
  }

  async fetch() {
    const { listUserGroup } = await executeQuery<UserGroupResponse, {}>(USER_GROUP_LIST, {});
    return listUserGroup;
  }
}

export class GqlUserResetPassword implements UserResetPassword {
  async reset(input: ResetPasswordInput) {
    const { changePassword, errors } = await executeMutation<ResetPasswordMutation>(USER_RESET_PASSWORD_MUTATION, { data: input });
    return { changePassword, errors };
  }
}

export class RestListMenuUser implements ListUserMenu {
  constructor(
    private readonly http: {
      get: (url: string) => Promise<{ data: Menu[] }>;
    },
  ) {}

  async fetch() {
    const { data } = await this.http.get(`${env.API_URL}/api/user/menus`);
    return data;
  }
}
