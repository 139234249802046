import axios from 'axios';
import env from 'config/env';
import { makeUserData } from 'main/factories/user';

interface IRegionIBGE {
  id: number;
  sigla: string;
  nome: string;
}

export interface IStatesIBGE {
  id: number;
  sigla: string;
  nome: string;
  regiao: IRegionIBGE;
}

export interface State {
  id: number;
  name: string;
  sigla: string;
}

export interface City {
  id: number;
  nome: string;
  mesorregiao?: any;
}

export const getIbgeStates = async (): Promise<State[]> => {
  const url = 'https://servicodados.ibge.gov.br/api/v1/localidades/estados';
  const { data } = await axios.get<IStatesIBGE[]>(url);
  return data.map((d) => ({ id: d.id, name: d.nome, sigla: d.sigla }));
};

export const getIbgeCities = async (stateId: number): Promise<City[]> => {
  const url = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateId}/municipios`;
  const { data } = await axios.get<City[]>(url);
  return data.map((d) => ({ id: d.id, nome: d.nome }));
};

export const downloadPdfFile = async (params: { url: string; filename: string }) => {
  const { url, filename } = params;
  const userData = makeUserData();
  const apiUrl = getApiUrl() + url;
  const response = await axios({
    url: apiUrl,
    method: 'GET',
    responseType: 'blob', // important
    headers: {
      Authorization: `Bearer ${userData.getToken()}`,
    },
    withCredentials: true,
  });
  const file_url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = file_url;
  link.setAttribute('download', `${filename || 'file'}.pdf`); //or any other extension
  document.body.appendChild(link);
  link.click();
};

export const makeHttpRequest = () => {
  const userData = makeUserData();
  return axios.create({
    baseURL: env.API_URL,
    headers: {
      Authorization: `Bearer ${userData.getToken()}`,
      'x-tenant-host': env.TENANT_HOST,
    },
    withCredentials: true,
  });
};

export const getApiUrl = () => import.meta.env.VITE_API_URL || '';
