import { GqlGetPatientById, GqlGetProfessionalById, GqlListPatient, GqlListProfessional, GqlSavePatient, GqlSavePatientAssociation, GqlSaveProfessional } from '@diegodfreire/sade-gql-client-js';

import client from './client';

export const makeListProfessional = () => new GqlListProfessional(client);

export const makeGetProfessionalById = () => new GqlGetProfessionalById(makeListProfessional());

export const makeSaveProfessional = () => new GqlSaveProfessional(client);

export const makeListPatient = () => new GqlListPatient(client);

export const makeGetPatientById = () => new GqlGetPatientById(makeListPatient());

export const makeSavePatient = () => new GqlSavePatient(client);

export const makeSavePatientAssociate = () => new GqlSavePatientAssociation(client);
