import { IconProps } from './icon.types';

export const UserCircleDuoIcon = ({ className = '', size = 20, color = 'currentColor' }: IconProps) => {
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.5" cx="12" cy="9" r="3" stroke={color} strokeWidth="1.5" />
      <circle cx="12" cy="12" r="10" stroke={color} strokeWidth="1.5" />
      <path opacity="0.5" d="M17.9691 20C17.81 17.1085 16.9247 15 11.9999 15C7.07521 15 6.18991 17.1085 6.03076 20" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};

export const PasswordDuoIcon = ({ className = '', size = 20, color = 'currentColor' }: IconProps) => {
  return (
    <svg className={className} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.5"
        d="M2 12C2 8.22876 2 6.34315 3.17157 5.17157C4.34315 4 6.22876 4 10 4H14C17.7712 4 19.6569 4 20.8284 5.17157C22 6.34315 22 8.22876 22 12C22 15.7712 22 17.6569 20.8284 18.8284C19.6569 20 17.7712 20 14 20H10C6.22876 20 4.34315 20 3.17157 18.8284C2 17.6569 2 15.7712 2 12Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path d="M12 10V14M10.2676 11L13.7317 13M13.7314 11L10.2673 13" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M6.73241 10V14M4.99999 11L8.46409 13M8.46386 11L4.99976 13" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M17.2681 10V14M15.5356 11L18.9997 13M18.9995 11L15.5354 13" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};
